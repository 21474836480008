export const LOAD_POSTS_REQUEST = "LOAD_POSTS_REQUEST";
export const LOAD_POSTS_SUCCESS = "LOAD_POSTS_SUCCESS";
export const LOAD_POSTS_FAIL = "LOAD_POSTS_FAIL";
export const REMOVE_USER_FROM_POSTLIST = "REMOVE_USER_FROM_POSTLIST";

export const ADD_POSTS_REQUEST = "ADD_POSTS_REQUEST";
export const ADD_POSTS_SUCCESS = "ADD_POSTS_SUCCESS";
export const ADD_POSTS_FAIL = "ADD_POSTS_FAIL";

export const LOAD_POSTS_LIST_REQUEST = "LOAD_POSTS_LIST_REQUEST";
export const LOAD_POSTS_LIST_SUCCESS = "LOAD_POSTS_LIST_SUCCESS";
export const LOAD_POSTS_LIST_FAIL = "LOAD_POSTS_LIST_FAIL";

export const SEARCH_POSTS_REQUEST = "SEARCH_POSTS_REQUEST";
export const SEARCH_POSTS_SUCCESS = "SEARCH_POSTS_SUCCESS";
export const SEARCH_POSTS_FAIL = "SEARCH_POSTS_FAIL";
