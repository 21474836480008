export const BLACK_BACKGROUND = "BLACK_BACKGROUND";
export const LIGHT_BACKGROUND = "LIGHT_BACKGROUND";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_NAVBAR = "CHANGE_NAVBAR";
export const CHANGE_MODAL = "CHANGE_MODAL";

export const COUNT_VISITORS = "COUNT_VISITORS";

export const SHOW_SUCCESS = "SHOW_SUCCESS";
