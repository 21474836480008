export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const LOAD_ALL_USERS_REQUEST = "LOAD_ALL_USERS_REQUEST";
export const LOAD_ALL_USERS_SUCCESS = "LOAD_ALL_USERS_SUCCESS";
export const LOAD_ALL_USERS_FAIL = "LOAD_ALL_USERS_FAIL";
export const LOAD_ALL_USERS_RESET = "LOAD_ALL_USERS_RESET";
export const ADD_USER_TO_USERLIST = "ADD_USER_TO_USERLIST";
export const REMOVE_USER_FROM_USERLIST = "REMOVE_USER_FROM_USERLIST";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";
