import styled from "styled-components";

export const LoginContainer = styled.div`
  display: fixed;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  height: 100vh;
`;
